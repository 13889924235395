/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconYoutube1 = ({ className }) => {
  return (
    <svg
      className={`icon-youtube-1 ${className}`}
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M21.593 7.70301C21.4791 7.28041 21.2565 6.89501 20.9473 6.58518C20.6382 6.27534 20.2533 6.05187 19.831 5.93701C18.265 5.50701 12 5.50001 12 5.50001C12 5.50001 5.73596 5.49301 4.16896 5.90401C3.74689 6.02415 3.36279 6.25078 3.05353 6.56214C2.74427 6.8735 2.52025 7.25913 2.40296 7.68201C1.98996 9.24801 1.98596 12.496 1.98596 12.496C1.98596 12.496 1.98196 15.76 2.39196 17.31C2.62196 18.167 3.29696 18.844 4.15496 19.075C5.73696 19.505 11.985 19.512 11.985 19.512C11.985 19.512 18.25 19.519 19.816 19.109C20.2385 18.9943 20.6237 18.7714 20.9336 18.4622C21.2435 18.153 21.4673 17.7682 21.583 17.346C21.997 15.781 22 12.534 22 12.534C22 12.534 22.02 9.26901 21.593 7.70301ZM9.99596 15.505L10.001 9.50501L15.208 12.51L9.99596 15.505Z"
        fill="#A04613"
      />
    </svg>
  );
};
