import React from "react";
import { StylePrimarySmall } from "../../components/StylePrimarySmall";
import { StyleSecondary } from "../../components/StyleSecondary";
import { IconFacebook1 } from "../../icons/IconFacebook1";
import { IconInstagram1 } from "../../icons/IconInstagram1";
import { IconX1 } from "../../icons/IconX1";
import { IconYoutube1 } from "../../icons/IconYoutube1";
import {IconLinkedin} from "../../icons/IconLinkedin";
import "./style.css";

export const Box = () => {
  return (
    <div className="box">
      <div className="primary-sitemap">
        <div className="home">
          <div className="overlap-group">
            <div className="home-fullscreen">
              <div className="navbar">
                <div className="container">
                  <img className="group" alt="Group" src="/img/group-1.png" />
                  <div className="column">
                    <div className="div">
                      <div className="text-wrapper-2">About Us</div>
                      <div className="text-wrapper-2"><a href="mailto:sales@airshoota.com">Contact</a></div>
                      <div className="nav-link-dropdown">
                        <div className="text-wrapper-2">More</div>
                        <img className="icon-instance-node" alt="Chevron down" src="/img/chevron-down.svg" />
                      </div>
                    </div>
                    <div className="actions">
                      <StyleSecondary
                        className="style-secondary-small-true-dark-mode-false-icon-position-no-icon"
                        divClassName="style-secondary-instance"
                        text="Learn"
                      />
                      <StylePrimarySmall
                        className="style-primary-small-true-dark-mode-false-icon-position-no-icon"
                        divClassName="style-primary-small-instance"
                        text="Sign Up"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="header">
                <div className="content-wrapper">
                  <div className="content">
                    <p className="medium-length-hero">NO MORE PAIN LOADING GBB.</p>
                    <p className="lorem-ipsum-dolor">Meet The Omni Ram, the sole GBB loader you&#39;ll ever require.</p>
                  </div>
                </div>
              </div>
              <div className="layout">
                <div className="content-2">
                  <div className="column-2">
                    <div className="subheading">Revolutionary</div>
                    <div className="heading">CLASSIC DESIGN, EVOLVED.</div>
                  </div>
                  <div className="column-3">
                    <p className="text-suspendisse">
                      The Omni Ram is the sole GBB loader you&#39;ll ever require. We have developed an ideal design for
                      loading most types of gas-blow back airsoft rifles and pistols, maintaining simplicity and
                      durability.
                    </p>
                    <div className="actions-2">
                      <button className="button-wrapper">
                        <button className="button-2">Learn More</button>
                      </button>
                      <div className="style-link-small">
                        <button className="button-2">Follow our socials</button>
                        <img
                          className="icon-instance-node"
                          alt="Icon chevron right"
                          src="/img/icon-chevron-right.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <iframe className={"placeholder-lightbox"} alt="Placeholder lightbox" src={"https://www.youtube.com/embed/dQw4w9WgXcQ?si=Q4n_5Ry5s2lhfgpa?autoplay=1&mute=1"}/>
              </div>
              <div className="layout-2">
                <div className="section-title">
                  <div className="subheading-2">Innovative</div>
                  <div className="content-3">
                    <p className="p">‘‘The only GBB loader you will ever need.’’</p>
                    <p className="text">Making Gas Blowback Airsoft Practical</p>
                  </div>
                </div>
                <div className="content-4">
                  <div className="row">
                    <div className="div-2">
                      <img className="placeholder-image" alt="Placeholder image" src="/img/placeholder-image.png" />
                      <div className="content-5">
                        <p className="heading-2">Unmatched Compatibility for Most GBBs</p>
                        <p className="text-2">
                          The Universal GBB Loader is designed to work seamlessly with a wide range of airsoft guns,
                          ensuring compatibility with your favorite models.
                        </p>
                        <button className="div-wrapper">
                          <button className="button-2">Learn More</button>
                        </button>
                      </div>
                    </div>
                    <div className="div-2">
                      <img className="img" alt="Placeholder image" src="/img/placeholder-image-1.png" />
                      <img className="union" alt="Union" src="/img/union.svg" />
                    </div>
                    <div className="div-2">
                      <img className="img" alt="Placeholder image" src="/img/placeholder-image-2.png" />
                      <div className="content-3">
                        <p className="heading-2">Comprehensive Set of Accessories Included</p>
                        <p className="text-2">
                          With the Universal GBB Loader, you&#39;ll receive the loader body, charging handle, pellet
                          tube, harness band, and funnel, providing everything you need for a complete airsoft loading
                          solution.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="style-link-small">
                  <button className="button-2">Follow our socials</button>
                  <img className="icon-instance-node" alt="Icon chevron right" src="/img/icon-chevron-right-1.svg" />
                </div>
              </div>
              <div className="gallery">
                <div className="section-title-2">
                  <div className="heading-3">Image Gallery</div>
                  <p className="text">Browse through stunning images of our Universal GBB Loader</p>
                </div>
                <div className="content-6">
                  <img className="placeholder-image-2" alt="Placeholder image" src="/img/placeholder-image-1-1.png" />
                  <div className="div-2">
                    <img className="placeholder-image-3" alt="Placeholder image" src="/img/placeholder-image-3.png" />
                    <img className="placeholder-image-3" alt="Placeholder image" src="/img/placeholder-image-3.png" />
                  </div>
                </div>
              </div>
              <div className="footer">
                <div className="content-7">
                  <img className="logo" alt="Logo" src="/img/logo.svg" />
                  <div className="div">
                    <div className="text-wrapper-3">About Us</div>
                    <div className="text-wrapper-3">FAQ</div>
                    <div className="text-wrapper-3">Career</div>
                    <div className="text-wrapper-3">Contact</div>
                    <div className="text-wrapper-3">News</div>
                  </div>
                  <div className="social-links">
                    <IconFacebook1 className="icon-instance-node" color="#A04613" />
                    <IconInstagram1 className="icon-instance-node" color="#A04613" />
                    <IconX1 className="icon-instance-node" />
                    <IconYoutube1 className="icon-instance-node" />
                    <IconLinkedin />
                  </div>
                </div>
                <div className="credits">
                  <div className="divider" />
                  <div className="div-3">
                    <p className="element-relume-all">© 2024 Airshoota. All rights reserved.</p>
                    <div className="div-3-inner">
                      <div className="text-wrapper-5">Privacy Policy</div>
                      <div className="text-wrapper-5">Terms of Service</div>
                      <div className="text-wrapper-5">Cookies Settings</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-3">{""}</div>
          </div>
          <div className="text-4">{""}</div>
        </div>
      </div>
    </div>
  );
};
